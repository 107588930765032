import { GetStaticPaths, GetStaticProps } from 'next';
import Head from 'next/head';

import { App } from '../../../apps/apiDocumentation/App';
import { LoadScripts } from '../../../components/LoadScripts';
import { getAllPages } from '../../../helpers/getAllPages';
import { getNavigation } from '../../../helpers/getNavigation';
import { getPage } from '../../../helpers/getPage';
import { getSubnavigation } from '../../../helpers/getSubnavigation';
import { getTableOfContents } from '../../../helpers/getTableOfContents';
import { useHiddenPageGuard } from '../../../hooks/useHiddenPageGuard';
import { Navigation, Page } from '../../../types';

interface Props {
  page: Page;
  navigation?: Navigation;
  subnavigation?: Navigation;
}

const Docs = ({ page, navigation, subnavigation }: Props) => {
  const { isHidden } = useHiddenPageGuard({ config: page.frontmatter });

  // Avoids rendering content for hidden pages before browser takes over and redirects to 404 page
  if (isHidden) {
    return null;
  }

  return (
    <>
      <LoadScripts />
      <Head>
        <title>{page.frontmatter.title || 'Wise Open API'}</title>
        {/* Override some default values inherited from crab */}
        <meta
          key="twitter:description"
          property="twitter:description"
          content="Integrate with Wise Platform to offer best-in-class FX products to your customers: fast, easy, low-cost and transparent."
        />
        <meta
          name="description"
          content="Integrate with Wise Platform to offer best-in-class FX products to your customers: fast, easy, low-cost and transparent."
        />
        <meta
          key="og:description"
          property="og:description"
          content="Integrate with Wise Platform to offer best-in-class FX products to your customers: fast, easy, low-cost and transparent."
        />
        {page.frontmatter.search?.externalIndex === false ? (
          <meta key="robots" name="robots" content="noindex,nofollow" />
        ) : (
          <meta key="robots" name="robots" content="index, follow" />
        )}
      </Head>
      <App page={page} navigation={navigation} subnavigation={subnavigation} />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const slugArray = (params?.slug || []) as string[];
  const path = `/${slugArray.join('/')}`;
  const pages = await getAllPages();
  const page = getPage({ pages, path });

  if (!page) {
    return { notFound: true };
  }

  const toc = getTableOfContents({
    page,
    currentPath: path,
    basePath: '/api-docs',
  });
  const navigation = getNavigation({
    navigationPath: '/navigation/main.json',
    currentPath: path,
    basePath: '/api-docs',
  });
  const subnavigation = getSubnavigation({ pages, page, toc, basePath: '/api-docs' });

  return {
    props: { page, navigation, subnavigation },
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await getAllPages();

  const allSlugs = pages.flatMap(({ frontmatter, subpages }) => {
    const mainPagePath = (frontmatter.path || '').replace(/^\//, ''); // turn "/api-reference" into "api-reference"

    const subpagesPaths = (subpages ?? []).map(
      (subpage) => `${mainPagePath}/${subpage.frontmatter.handle || ''}`,
    );

    return [mainPagePath, ...subpagesPaths];
  });
  const paths = allSlugs.map((slug) => ({ params: { slug: slug.split('/') } }));

  return {
    paths,
    fallback: false,
  };
};

export default Docs;

import { alertTag, Alert } from '../components/Alert';
import { ApiRef, ApiRefLink, apiRefTag } from '../components/ApiRef';
import { Decision, decisionTag } from '../components/Decision';
import { Endpoint, endpointTag } from '../components/Endpoint';
import { ExampleBox, exampleBoxTag } from '../components/ExampleBox';
import { Feedback, feedbackTag } from '../components/Feedback';
import { Fence, fenceNode } from '../components/Fence';
import { Fields, Field, fieldsTag, fieldTag } from '../components/Fields';
import { Heading, headingNode } from '../components/Heading';
import { HeroBanner } from '../components/HeroBanner';
import { Icon } from '../components/Icon';
import { Image, imageTag } from '../components/Image';
import { InstructionsList, instructionsListTag } from '../components/InstructionsList';
import { IntroCards, introCardsTag } from '../components/IntroCards';
import {
  PostmanRunButton,
  postmanRunButtonTag,
  PostmanRunButtonExampleBox,
  postmanRunButtonExampleBoxTag,
} from '../components/PostmanRunButton';
import { Reference, ReferenceTag } from '../components/Reference';
import { SearchArea } from '../components/SearchArea';
import { Section, PanelLeft, PanelRight } from '../components/Section';
import {
  Select,
  selectTag,
  Option,
  optionTag,
  SelectContextProvider,
  selectContextProviderTag,
} from '../components/Select';
import { Video, videoTag } from '../components/Video';
import { TableToFieldset } from '../utils/table-to-fieldset/TableToFieldset';

export const transformConfig = {
  nodes: {
    fence: fenceNode, // https://spec.commonmark.org/0.30/#fenced-code-blocks
    heading: headingNode,
  },
  tags: {
    br: { render: 'Br' },
    icon: {
      render: 'Icon',
      attributes: {
        icon: {
          type: String,
          description: 'An icon from wise icons',
        },
      },
    },
    'hero-banner': { render: 'HeroBanner' },
    'api-ref': apiRefTag,
    reference: ReferenceTag,
    section: { render: 'Section' },
    title: { render: 'Title' },
    'panel-left': { render: 'PanelLeft' },
    'panel-right': { render: 'PanelRight' },
    'example-box': exampleBoxTag,
    endpoint: endpointTag,
    feedback: feedbackTag,
    fields: fieldsTag,
    field: fieldTag,
    decision: decisionTag,
    'instructions-list': instructionsListTag,
    'intro-cards': introCardsTag,
    'table-to-fieldset': { render: 'TableToFieldset' },
    alert: alertTag,
    video: videoTag,
    image: imageTag,
    select: selectTag,
    'select-context-provider': selectContextProviderTag,
    option: optionTag,
    'search-area': { render: 'SearchArea' },
    'postman-run-button': postmanRunButtonTag,
    'postman-run-button-example-box': postmanRunButtonExampleBoxTag,
  },
} as const;

export const renderConfig = {
  components: {
    Br: () => <br />,
    HeroBanner,
    Icon,
    ApiRef,
    ApiRefLink,
    Reference,
    Section,
    PanelLeft,
    PanelRight,
    ExampleBox,
    Endpoint,
    Fence,
    Feedback,
    Fields,
    Field,
    Decision,
    InstructionsList,
    IntroCards,
    TableToFieldset,
    Alert,
    Video,
    Image,
    SearchArea,
    SelectContextProvider,
    Select,
    Option,
    PostmanRunButton,
    PostmanRunButtonExampleBox,
    Heading,
  },
} as const;

export function preprocessRawContent(rawContent: string): string {
  return rawContent.replaceAll(/<br\s*\/?>/g, '{% br /%}');
}

export const getFeedbackSectionId = (feedbackElement: HTMLParagraphElement) => {
  const heading = getParentHeading(feedbackElement.offsetTop);

  if (heading?.id) {
    return `#${heading.id}`;
  }

  if (heading?.tagName.toLowerCase() === 'h1') {
    return '#intro';
  }

  return '';
};

// Finds closest heading element, only considering h1 and h2 and looking up
const getParentHeading = (position: number) => {
  const headings = document.querySelectorAll('h1, h2');
  let bestMatch;
  for (const heading of Array.from(headings)) {
    if (heading instanceof HTMLHeadingElement) {
      // heading is below the position, no need to check further
      if (heading.offsetTop > position) {
        break;
      }

      // store current match as best, but keep on checking with the next element
      bestMatch = heading;
    }
  }
  return bestMatch;
};

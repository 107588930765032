import { Button, ControlType, Priority, Size, ProcessIndicator } from '@transferwise/components';
import { Cross as CloseIcon, Search as SearchIcon } from '@transferwise/icons';
import type { HttpClient } from '@transferwise/service-comms';
import { debounce } from 'lodash';
import React, { useState } from 'react';

import { getSearchResults } from '../../helpers/search/getSearchResults';
import { useEffectWithoutInitialRender } from '../../hooks/useEffectWithoutInitialRender';
import { FuseResults } from '../../types/search';
import { createHttpClient } from '../../utils/createHttpClient';
import SearchResults from '../SearchResults/SearchResults';

import styles from './SearchArea.module.scss';
import { trackViaServer } from '../../helpers/tracking';

const SEARCH_DELAY = 900;
const delayedSearch = debounce(
  async (httpClient: HttpClient, query: string, callback: (results: FuseResults[]) => void) => {
    const result = await getSearchResults(httpClient, query);
    callback(result);
    return result;
  },
  SEARCH_DELAY,
  { leading: true },
);

export const SearchArea = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState<string>('');
  const [searchResults, setSearchResults] = useState<FuseResults[] | null>(null);
  const httpClient = createHttpClient();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setKeyword(query);
  };

  const clearSearch = () => {
    setKeyword('');
    setSearchResults(null);
    setIsLoading(false);
  };

  useEffectWithoutInitialRender(() => {
    if (keyword.length <= 2) {
      setSearchResults(null);
    } else {
      setIsLoading(true);
      delayedSearch(httpClient, keyword, (results) => {
        setSearchResults(results);
        setIsLoading(false);
        void trackViaServer(httpClient, 'Search complete', { keyword });
      })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    }
  }, [keyword]);

  return (
    <div className={styles.container}>
      <h1>Search Wise Platform Documentation</h1>
      <div className="search-input-wrapper">
        <div className="input-group">
          <input
            className="search-input form-control"
            type="text"
            value={keyword}
            placeholder="Search..."
            onChange={onChange}
          />
          <div className="input-group-addon search-input-addon p-a-0">
            {keyword ? (
              <Button
                type={ControlType.ACCENT}
                priority={Priority.TERTIARY}
                size={Size.SMALL}
                className="p-x-1"
                onClick={clearSearch}
              >
                <CloseIcon size={24} />
              </Button>
            ) : (
              <div className="p-x-1">
                <SearchIcon size={24} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <ProcessIndicator size={Size.EXTRA_SMALL} className="m-a-auto m-y-5" />}
      {searchResults && !isLoading && <SearchResults results={searchResults} keyword={keyword} />}
    </div>
  );
};

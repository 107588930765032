import { Button, ControlType, Priority, RadioGroup, Size } from '@transferwise/components';

import styles from './Feedback.module.scss';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { getFeedbackSectionId } from './getFeedbackSectionId';
import { trackViaServer } from '../../helpers/tracking';
import { createHttpClient } from '../../utils/createHttpClient';

type Step = 'no-reason' | 'success' | 'error';

// Creates a "Was it helpful? yes no" paragraph
// Results get sent to mixpanel
export const Feedback = () => {
  const [step, setStep] = useState<Step>();
  const feedbackElement = useRef<HTMLParagraphElement>(null);
  const httpClient = createHttpClient();

  const handleSubmit = async (eventName: string, data: Record<string, string>) => {
    if (!feedbackElement.current) {
      return null;
    }
    const path = window.location.pathname;
    const section = getFeedbackSectionId(feedbackElement.current);
    const id = path + section;

    try {
      return await trackViaServer(httpClient, eventName, {
        path, // example: "/api-docs/guides/customer-account-partner-kyc"
        section, // "#intro"
        id, // path + section
        feedbackId: id,
        ...data,
      });
    } catch {
      setStep('error');
    }
    return null;
  };

  if (step === 'success') {
    return <p className="text-muted">Thanks for the feedback!</p>;
  }

  if (step === 'error') {
    return <p className="text-muted">Oops! We were not able to collect the feedback.</p>;
  }

  return (
    <div ref={feedbackElement}>
      {step === 'no-reason' ? (
        <div>
          <p className="text-muted">What went wrong?</p>
          <div className="d-inline-block m-b-2">
            <RadioGroup
              name="feedback-form-no-reason"
              radios={[
                {
                  value: 'inaccurate-or-outdated-information',
                  label: 'Inaccurate or outdated information',
                },
                {
                  value: 'difficult-to-understand',
                  label: 'Difficult to understand',
                },
                {
                  value: 'lacked-detail-or-examples',
                  label: 'Lacked detail or examples',
                },
                {
                  value: 'did-not-solve-my-issue',
                  label: "Didn't solve my issue",
                },
                {
                  value: 'poor-formatting',
                  label: 'Poor formatting',
                },
              ]}
              onChange={async (reason) => {
                await handleSubmit('Feedback additional info', { reason }).then(() => {
                  setStep('success');
                });
              }}
            />
          </div>
        </div>
      ) : (
        <p className="d-flex align-items-center">
          <span className={classNames(styles.label, 'text-muted')}>Was this section helpful?</span>
          <Button
            size={Size.MEDIUM}
            type={ControlType.ACCENT}
            priority={Priority.TERTIARY}
            className={styles.btn}
            onClick={async () => {
              await handleSubmit('Feedback', { reply: 'yes' }).then(() => {
                setStep('success');
              });
            }}
          >
            Yes
          </Button>
          <Button
            size={Size.MEDIUM}
            type={ControlType.ACCENT}
            priority={Priority.TERTIARY}
            className={styles.btn}
            onClick={async () => {
              await handleSubmit('Feedback', { reply: 'no' }).then(() => {
                setStep('no-reason');
              });
            }}
          >
            No
          </Button>
        </p>
      )}
    </div>
  );
};
